.section--interventi{
    background-color: $color-bg-light;

    .background{
        img{
            margin-left: 0;
            margin-right: auto;

            @include mq(md) {
                margin-left: auto;
                margin-right: 0;
            }
        } 
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);

        .bottom-text{
            @include paragraph-md($color-black, 400);

            .text-container{
                @include make-col(22);

                @include mq(md) {
                    @include make-col(6);
                }
            }

            @include mq(md) {
                margin-top: auto;
            }
        }
    }

    @include mq(md) {
        padding-top: pxrem(102);
    }
}