.section--approccio{
    // background-color: $color-azure;
    background-color: $color-bg-light;

    .background{
        img{
            margin-left: 0;
        }
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);

        .right-container{
            margin-bottom: auto;

            .right-content{
                @include make-col(22);

                .text-content{
                    @include paragraph-md($color-black, 400);
                    margin-top: pxrem(30);
                    margin-bottom: pxrem(30);

                    @include mq(md) {
                        margin-top: pxrem(35);
                    }
                }

                @include mq(md) {
                    @include make-col(7);
                    @include make-col-offset(12);
                }
            } 

            @include mq(md) {
                margin-top: auto;
            }
        }
    }

    @include mq(md) {
        padding-top: pxrem(102);
    }
}