#fp-nav{
    display: none;
}
.header {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: $color-white;
    transition: all $transition-default;

    .header-wrapper {
        @include make-col(22);
        @include make-col-offset(1);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: pxrem(20);
        padding-top: pxrem(20);
        position: relative;
        // z-index: 4;

        .logo {
            align-items: center;
            display: flex;

            a {
                svg{
                    @include icon-fill($color-black);
                }
                
                &:hover {
                    opacity: 0.8;
                }
            }

            @include mq(md) {
                margin: 0;
            }
        }

        .header-menu {
            @include paragraph-md($color-black, 400);
            letter-spacing: pxrem(-1);
            display: flex;

            .header-menu-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                width: 100%;
                margin-top: auto;
                margin-bottom: auto;

                @include mq(md) {
                    justify-content: flex-end;
                }

                .primary-menu {

                    align-items: center;
                    display: flex;
                    justify-content: flex-end;

                    .menu--primary {
                        padding-inline-start: 0;
                        display: none;

                        @include mq(md) {
                            display: flex;
                            align-items: center;
                        }

                        li {
                            list-style: none;
                            min-width: pxrem(80);
                            text-align: center;

                            &:first-child {

                                @include mq(md) {
                                    margin-left: 0;
                                }
                            }

                            a {
                                @include link($color-black, $color-black, none);
                                padding: 0;

                                &.active{
                                    pointer-events: none;
                                    font-weight: 700;
                                    // text-decoration: underline;
                                }

                                &:hover{
                                    // opacity: 0.8;
                                    opacity: 1;
                                    font-weight: bold;
                                }
                            }

                            .btn--link{
                                background-color: $color-black;
                                border-radius: 100px;
                                box-shadow: none;
                                align-items: center;
                                color: $color-white;
                                display: inline-flex;
                                padding: pxrem(5) pxrem(20);
                                border: 1px solid $color-black;

                                &:hover{
                                    box-shadow: 0px 5px 10px rgba(0, 0, 0, .20);
                                    color: $color-white;
                                    opacity: 1;
                                    font-weight: normal;
                                }
                            }

                            @include mq(md) {
                                display: inline;
                                margin-left: pxrem(30);
                            }
                        }
                    }
                }
            }

        }
    }

    &.transparent {
        background-color: transparent;

        .header-wrapper {

            .logo {
                a {
                    pointer-events: none;;
                    svg {
                        @include icon-fill($color-black);
                    }
                }
            }

            .header-menu {

                .header-menu-wrapper {

                    .primary-menu {

                        .menu--primary {

                            li {

                                a {
                                    @include link($color-black, $color-black, none);

                                    &:hover{
                                        opacity: 0.8;
                                    }
                                }

                                .btn--link{
                                    color: $color-white;

                                    &:hover{
                                        color: $color-white;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq(md) {
        display: block;
    }
}