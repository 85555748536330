.section--call-for-artists{
    // background-color: $color-green;
    background-color: $color-black;

    .background{
        display: flex;
        height: 100%;

        picture{
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            margin-top: auto;

            img{
                width: 100%;
                max-height: 10dvh;
            } 

            @include mq(md) {
                margin-top: 0;

                img{
                    margin-left: auto;
                    margin-right: 0;
                    margin-top: 0;
                    max-height: unset;
                    width: unset;
                }
            }
        }   
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);

        .section-label{
            background-color: $color-white;
            color: $color-black;
        }

        .title-xl{
            color: $color-white;
            max-width: unset;

            @include mq(md) {
                max-width: 55vw;
            }
        }

        .bottom-text{
            @include paragraph-md($color-white, 400);

            .text-container{
                @include make-col(22);
                margin-bottom: pxrem(30);

                .description-desktop{
                    display: none;

                    @include mq(md) {
                        display: block;
                    }
                }

                .description-mobile{
                    display: block;

                    @include mq(md) {
                        display: none;
                    }
                }

                @include mq(md) {
                    @include make-col(11);
                }
            }

            @include mq(md) {
                margin-top: auto;
            }
        }

        .buttons{
            margin-top: 0;

            .btn--cta-white{
                &:not(:first-of-type){
                    margin-top: pxrem(10);

                    @include mq(md) {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                &.bg-white{
                    color: $color-black;
                }
            }

            @include mq(md) {
                margin-top: 10vh;
            }
        }
    }

    @include mq(md) {
        padding-top: pxrem(102);
    }
}