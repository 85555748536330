.section--chi-siamo{
    // background-color: $color-orange;
    background-color: $color-bg-light;

    .background{
        img{
            margin-left: auto;
            margin-right: auto;
            width: 100%;

            @include mq(md) {
                margin-left: 0;
                margin-right: auto;
                width: auto;
            }
        } 
    }

    .section-container{
        padding-top: pxrem(30);
        padding-bottom: pxrem(144);
        flex-direction: column;

        .left-container{

            .section-label{
                // color: $color-orange;
                color: $color-bg-light;
            }

            @include mq(md) {
                @include make-col(12);
            }
        }

        .right-container{
            @include make-col(22);
            height: auto;

            .right-column{
                height: 100%;

                .right-content{
                    @include make-col(22);
                    @include paragraph-md($color-black, 400);

                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    .bottom-text{
                        @include paragraph($color-black, 400);
                        margin-top: auto;
                    }

                    @include mq(md) {
                        @include make-col(22);
                        @include make-col-offset(1);

                        .top-text,
                        .bottom-text{
                            p{
                                margin: 0;
                            }   
                        }
                    }
                }  
            }

            @include mq(md) {
                @include make-col(10);
                border-left: 1px solid $color-black;
                height: 100%;
            }
        }

        @include mq(md) {
            flex-direction: row;
        }
    }

    @include mq(md) {
        @include paragraph($color-white, 400);
        padding-top: pxrem(102);
    }
}