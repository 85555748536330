.btn {
  @include button-reset;
  cursor: pointer;
  display: inline-block;
  font-size: pxrem(20);
  font-weight: 400;
  line-height: 1.6;
  position: relative;
  text-decoration: none;
  transition:  background-color $transition-default, box-shadow $transition-default, color $transition-default;
  will-change: background-color, box-shadow, color;

  &.btn--cta {
    background-color: $color-transparent;
    border-radius: 100px;
    box-shadow: none;
    align-items: center;
    color: $color-black;
    display: inline-flex;
    padding: pxrem(5) pxrem(20);
    border: 1px solid $color-black;

    &:hover {
      // box-shadow: 0px 5px 10px rgba(0, 0, 0, .20);
      background-color: $color-black;
      color: $color-white;
      opacity:1;

      svg{
        @include icon-stroke($color-black);
        transition:  all $transition-default;
      }
    }
  }

  &.btn--cta-white {
    background-color: $color-transparent;
    border-radius: 100px;
    box-shadow: none;
    align-items: center;
    color: $color-white;
    display: inline-flex;
    padding: pxrem(5) pxrem(20);
    border: 1px solid $color-white;

    &.bg-white{
      background-color: $color-white;
    }

    &:hover {
      // box-shadow: 0px 5px 10px rgba(0, 0, 0, .20);
      background-color: $color-white;
      color: $color-black;
      opacity:1;

      svg{
        @include icon-stroke($color-white);
        transition:  all $transition-default;
      }
    }
  }
}