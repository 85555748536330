/************************/
/* Swiper general rules */
/************************/

.swiper {
	@include swiper-reset-buttons;
	z-index: 0;
	
	.swiper-wrapper {
		.swiper-slide {
			height: 100%;
			width: auto;
		}
	}
}

@include swiper-reset-buttons;

/*******************/
/* Custom swipers */
/*******************/

.slider-container{
	display: flex;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	text-align: center;

	.swiper--vertical {
		height: calc( 26vw * 2);
	
		.swiper-wrapper {
			height: 100%;
			transition-timing-function: linear;
	
			.swiper-slide {
				height: 26vw;
				opacity: 0;
				transition: all 1s ease-in;

				// &.gradient-text{
				// 	background-color:  $color-transparent;
				// 	background-image: linear-gradient(to top, $color-transparent, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.30), $color-black);
				// 	background-size: 100%;
				// 	-webkit-background-clip: text;
				// 	-moz-background-clip: text;
				// 	-webkit-text-fill-color: transparent; 
				// 	-moz-text-fill-color: transparent;
				// }
				
				&.swiper-slide-prev{
					opacity: 0;
					transform: rotateX(90deg);
					// &.gradient-text{
					// 	background-color:  $color-transparent;
					// 	background-image: linear-gradient(to bottom, $color-transparent, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.30), $color-black);
					// }
				}

				&.swiper-slide-active{
					transform: rotateX(0);
					opacity: 1;
				}

				&.swiper-slide-next{
					transform: rotateX(-90deg);
					opacity: 0;
				}
			}
		}
	}

	@include mq(md) {
		margin-top: auto;
		margin-left: pxrem(25);
		text-align: left;

		.swiper--vertical {
			height: calc( 14vw * 3);
			.swiper-slide {
				height: 14vw;
			}
		}
	}
}

.swiper--case-study{
	overflow: hidden;
	height: 100%;

	.swiper-wrapper {
		display: inline-flex;
		height: 100%;
		transition-timing-function: linear;

		.swiper-slide{
			display: flex;
			height: 100%;
		}
	}

	.swiper-navigation {
		display: flex;
		flex-flow: row-reverse;
		justify-content: space-between;
		position: absolute;
		bottom: pxrem(20);
		right: calc( 50% - pxrem(55));
		width: pxrem(110);
	
		@include mq(md){
			position: absolute;
			top: unset;
			bottom: pxrem(20);
			right: pxrem(50);
		}
	
		.swiper-button-next,
		.swiper-button-prev {
			background-color: black;
			border-radius: 2px;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
			color: unset;
			cursor: pointer;
			display: flex;
			height: unset;
			margin-top: 0;
			max-height: 50px;
			aspect-ratio: 1 / 1;
			position: relative;
			top: unset;
			width: unset;
			z-index: 2;

			svg {
				
				fill: $color-white;
				max-height: pxrem(25);
				margin: auto;
				stroke: $color-white;
			}
	
			&.swiper-button-disabled {
				opacity: 0.5;
				pointer-events: none;
			}
	
			&:after {
				display: none;
			}
		}
	
		.icon-arrow {
			margin-left: 0;
		}
	}
}