.section--case-study{
    // background-color: $color-green;
    background-color: $color-bg-light;

    .background{
        img{
            margin-left: auto;
            margin-right: 0;
        } 
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);
        width: 100%;

        .section-label{
            // color: $color-green;
            color: $color-bg-light;
        }

        .title-xl{
            max-width: unset;

            @include mq(md) {
                max-width: 55vw;
            }
        }

        .bottom-text{
            @include paragraph-md($color-black, 400);

            .text-container{
                @include make-col(22);

                small{
                    line-height: 1;
                }

                @include mq(md) {
                    @include make-col(6);
                }

                .btn{
                    margin-top: pxrem(10);
                }
            }

            @include mq(md) {
                margin-top: auto;
            }
        }
    }
    
    .image{
        display: flex;
        width: 100%;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        img{
            height: 100%;
            object-fit: cover;
            margin: auto;
            min-width: 100dvw;
            width: 100%;

            // @include mq(md) {
            //     object-fit: cover;
            // }
        }
    }

    &.alternative-layout{
        display: none;

        .section-container{
            position: absolute;

            .section-label{
                background-color: $color-white;
                color: $color-black;
                mix-blend-mode: screen; /* This makes the cutout text possible */
            }
    
            .title-xl{
                color: $color-white;
            }
    
            .bottom-text{
                color: $color-white;
            }
        }

        .image{
            &::after{
                content: '';
                opacity: 10%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $color-black;
            }
        }

        @include mq(md) {
            display: block;
        }
    }

    @include mq(md) {
        display: none;
        padding-top: pxrem(102);

        // .first-image{
        //     margin-left: -30vw;
        //     min-width: calc( 100dvw + 30vw );

        //     .image,
        //     img{
        //         min-width: calc( 100dvw + 30vw );
        //     }
        // }
    }
}