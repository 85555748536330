.section{
    background-color: bisque;
    position: relative;
    // width: 100vw;
    height: 100vh;
    height: calc(100vh - pxrem(100));
    height: 100dvh;

    .background{
        position: absolute;
        bottom: 0;
        left: 0;
        top:0;
        right: 0;

        img{
            height: 100%;
            object-fit: cover;
            margin: auto;
        }
    }
    .section-container{
        @include make-col(22);
        @include make-col-offset(1);
        display: flex;
        height: 100dvh;
        overflow: hidden;
        position: relative;
        z-index: 2;

        .section-label{
            background-color: $color-black;
            border-radius: pxrem(30);
            color: $color-white;
            font-size: pxrem(14);
            margin-bottom: pxrem(30);
            padding: pxrem(5) pxrem(10);
            width: max-content;

            @include mq(md) {
                font-size: pxrem(16);
                padding: pxrem(10) pxrem(20);
            }
        }

        .title-xxxl{
            @include title-1($color-black, normal);
            font-size: 32vw;
            line-height: 0.8;

            @include mq(md){
                font-size: 18vw;
            }
        }

        .title-xxl{
            @include title-1($color-black, normal);
            margin-top: auto;
            text-align: center;

            @include mq(md) {
                margin-bottom: auto;
                text-align: left;
            }
        }

        .title-xl{
            @include title-2($color-black, normal);
        }

        @include mq(md) {
            height: calc(100vh - pxrem(102));
        }
    }
}