// =============================================================================
// Sizes
// =============================================================================

// Max widths and fixed components heights
$size-maxwidth:         1300px;

$header-height:         50px;
$header-height-small:   35px;

// =============================================================================
// Colors
// =============================================================================

// Brand colors
$color-yellow:  #FFFF85;
$color-violet:  #DDC7FF;
$color-green:   #B3FFC1;
$color-azure:   #99E7FF;
$color-pink:    #FCD9EC;
$color-orange:  #FFCDAB;
$color-bg-dark: #C2B6A8;
$color-bg-light:#EFE9D9;

// Neutral colors
$color-white:           #FFFFFF;
$color-gray-lightest:   #f2f2f2;
$color-gray-light:      #F9F9F9;
$color-gray:            #5C5C5C;
$color-gray-dark:       #333333;
$color-black:           #000000;
$color-blue:            #195AC2;
$color-transparent:     rgb(255, 255, 255, 0);

$colors-map: (
  "yellow": $color-yellow,
  "white": $color-white,
  "gray-light": $color-gray-light,
  "gray": $color-gray,
  "gray-dark": $color-gray-dark,
  "black": $color-black,
  "blue": $color-blue,
);


// =============================================================================
// Typography
// =============================================================================

$font-primary: 'Inter', sans-serif;
$font-secondary:  'MangoGrotesque';

$font-xsmall:       pxrem(13);
$font-small:        pxrem(14);
$font-normal:       pxrem(16);
$font-medium:       pxrem(18);
$font-discrete:     pxrem(24);
$font-large:        pxrem(40);
$font-xlarge:       10vw;
$font-xxlarge:      14vw;


// =============================================================================
// Transitions
// =============================================================================

$transition-default: 250ms ease-in-out;