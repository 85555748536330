.desktop-footer{
    @include paragraph($color-white, 400);
    display: none;
    background-color: $color-black;
    height: pxrem(114);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .footer-content{
        @include make-col(22);
        @include make-col-offset(1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .footer-logo{
            svg{
                @include icon-fill($color-white);
                stroke-width: 0 !important;
            }
        }

        .footer-socials{
            display: flex;
            margin-bottom: pxrem(4);

            a{
                @include link($color-white, $color-white, none);
                width: max-content;

                &:not(:last-of-type){
                    margin-right: pxrem(20);
                }

                svg{
                    @include icon-fill($color-white); 
                }
            }  
        }

        .footer-links{
            display: inline-block;
            .footer-link{
                margin-right: pxrem(30);
                a{
                    @include link($color-white, $color-white, none);

                    &.underline{
                        text-decoration: underline;
                    }
                }  
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @include mq(md) {
        display: block;
    }
}

.section.section--mobile-footer{
    @include paragraph($color-white, 400);
    background-color: $color-black;
    display: block;
    height: auto;
    padding-top: pxrem(30);
    padding-bottom: pxrem(5);

    .footer-content{
        @include make-col(22);
        @include make-col-offset(1);

        .footer-logo{
            svg{
                @include icon-fill($color-white);
                stroke-width: 0 !important;
            }
        }

        .footer-socials{
            display: flex;
            margin-bottom: pxrem(10);
            margin-top: pxrem(10);

            @include mq(md) {
                margin-bottom: 0;
                margin-top: 0;
            }

            a{
                @include link($color-white, $color-white, none);
                width: max-content;

                &:not(:last-of-type){
                    margin-right: pxrem(20);
                }

                svg{
                    @include icon-fill($color-white); 
                }
            }  
        }

        .footer-link{
            margin-bottom: pxrem(4);
            a{
                @include link($color-white, $color-white, none);

                &.underline{
                    text-decoration: underline;
                }
            }  
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include mq(md) {
        display: none;
    }
}
