.section--projects-carousel{
    // background-color: $color-green;
    background-color: $color-bg-light;

    .background{
        img{
            margin-left: auto;
            margin-right: 0;
        } 
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);
        height: fit-content;
        position: absolute;
        // width: 100%;
        width: auto;

        .section-label{
            background-color: $color-white;
            color: $color-black;
            mix-blend-mode: screen; /* This makes the cutout text possible */
        }
    }

    .swiper-slide{
        position: relative;
    }
    
    .image{
        display: flex;
        width: 100%;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        img{
            height: 100%;
            object-fit: cover;
            margin: auto;
            min-width: 100dvw;
            width: 100%;
        }

        &::after{
            content: '';
            opacity: 50%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $color-black;
        }
    }

    .content{
        @include make-col(22);
        @include make-col-offset(1);
        display: flex;
        flex-direction: column;
        padding-bottom: pxrem(30);
        position: absolute;
        bottom:0 ;
        left: 0;
        top: pxrem(104);
        z-index: 2;

        .title-xl{
            @include title-2($color-white, normal);
            line-height: 1 !important;
        }

        .bottom-text{
            @include paragraph-md($color-white, 400);

            .text-container{
                @include make-col(22);

                small{
                    line-height: 1;
                }

                @include mq(md) {
                    @include make-col(6);
                }

                .btn{
                    margin-top: pxrem(10);
                }
            }

            @include mq(md) {
                margin-top: auto;
            }
        }
    }

    @include mq(md) {
        padding-top: pxrem(102);
    }

}