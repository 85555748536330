.marquee {
  --gap: 0rem;
  bottom: pxrem(20);
  display: flex;
  gap: var(--gap);
  position: absolute;
  overflow: hidden;
  user-select: none;
  width: 100%;
  z-index: 3;

  .marquee-inner {
    display: flex;
    flex-shrink: 0;
    gap: var(--gap);
    justify-content: space-around;
    min-width: 100%;
    will-change: transform;

    .marquee-item{
      margin-left: pxrem(20);
      position: relative;

      .label{
        @include paragraph-lg($color-black, 400);
        display: flex;

        a{
          @include link($color-black, $color-black, none);
        }

        .icon-arrow{
          margin-top: auto;
          margin-bottom: auto;
          margin-left: pxrem(10);
        }
      }
    }
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  /* Pause animation when reduced-motion is set */
  @media (prefers-reduced-motion: reduce) {
    .marquee-inner {
      animation-play-state: paused !important;
    }
  }

  /* Enable animation when elements are duplicated */
  &.marquee--enable-animation .marquee-inner {
    animation: scroll 45s linear infinite;
  }

  /* Pause animation when mouse is hover */
  &.marquee--pause-hover:hover .marquee-inner {
    animation-play-state: paused;
  }
}
