.section--faces-make-places{
    // background-color: $color-green;
    background-color: $color-blue;

    .background{
        img{
            display: none;
            margin-right: 0;

            @include mq(md) {
                display: block;
            }
        }
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);

        .section-label{
            background-color: $color-white;
            color: $color-blue;
        }

        .title-xl{
            color: $color-white;
            max-width: unset;

            @include mq(md) {
                max-width: 55vw;
            }
        }

        .bottom-text{
            @include paragraph-md($color-white, 400);

            .text-container{
                @include make-col(22);
                margin-bottom: pxrem(30);

                .description-desktop{
                    display: none;

                    @include mq(md) {
                        display: block;
                    }
                }

                .description-mobile{
                    display: block;

                    @include mq(md) {
                        display: none;
                    }
                }

                @include mq(md) {
                    @include make-col(11);
                }
            }

            .buttons{
                margin-top: 0;

                .btn--cta-white{
                    &:not(:first-of-type){
                        margin-top: pxrem(10);
                        margin-bottom: pxrem(10);

                        @include mq(md) {
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }

                    &.bg-white{
                        color: $color-blue;
                    }

                    &:hover{
                        box-shadow: 0px 5px 10px rgba(0, 0, 0, .20);
                        color: $color-blue;
                    }
                }

                @include mq(md) {
                    margin-top: 10vh;
                }
            }
            
            @include mq(md) {
                margin-top: auto;
            }
        }
    }

    @include mq(md) {
        padding-top: pxrem(102);
    }
}
.section--progetto{
    // background-color: $color-green;
    background-color: $color-bg-light;

    .background{
        img{
            margin-left: auto;
            margin-right: 0;

            // @include mq(md) {
            //     margin-left: auto;
            //     margin-right: 0;
            // }
        } 
    }

    .section-container{
        flex-direction: column;
        padding-top: pxrem(30);
        padding-bottom: pxrem(30);

        .section-label{
            // color: $color-green;
            color: $color-bg-light;
        }

        .title-xl{
            max-width: unset;

            @include mq(md) {
                max-width: 55vw;
            }
        }

        .bottom-text{
            @include paragraph-md($color-black, 400);

            .text-container{
                @include make-col(22);

                @include mq(md) {
                    @include make-col(6);
                }
            }

            @include mq(md) {
                margin-top: auto;
            }
        }
    }

    @include mq(md) {
        padding-top: pxrem(102);
    }
}