@font-face {
  font-family: "MangoGrotesque";
  src: url("../fonts/MangoGrotesque/Web-TT/MangoGrotesque-Bold.ttf") format("truetype"), url("../fonts/MangoGrotesque/Web-TT/MangoGrotesque-Bold.woff") format("woff"), url("../fonts/MangoGrotesque/Web-TT/MangoGrotesque-Bold.woff2") format("woff2");
}
* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  min-height: 100%;
  overflow: hidden !important;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  color: #000000;
  -webkit-text-size-adjust: 100%;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

[unselectable] {
  -webkit-touch-callout: none;
  user-select: none;
}

a {
  color: #000000;
  opacity: 1;
  transition: all 250ms ease-in-out;
}
a:hover {
  opacity: 0.8;
}

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%;
}
img.svg-symbols, media.svg-symbols, svg.svg-symbols {
  display: none;
}

ul, ol {
  margin: 0;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

/* Safari 9 specific styles */
@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  html {
    height: auto;
  }
}
.btn {
  -webkit-appearance: none;
  -webkit-user-select: none;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  font: inherit;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  position: relative;
  text-decoration: none;
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out, color 250ms ease-in-out;
  will-change: background-color, box-shadow, color;
}
.btn.btn--cta {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  box-shadow: none;
  align-items: center;
  color: #000000;
  display: inline-flex;
  padding: 0.3125rem 1.25rem;
  border: 1px solid #000000;
}
.btn.btn--cta:hover {
  background-color: #000000;
  color: #FFFFFF;
  opacity: 1;
}
.btn.btn--cta:hover svg {
  fill: none;
  stroke: #000000;
  transition: all 250ms ease-in-out;
}
.btn.btn--cta:hover svg circle,
.btn.btn--cta:hover svg path,
.btn.btn--cta:hover svg polygon,
.btn.btn--cta:hover svg rect {
  fill: none;
  stroke: #000000;
}
.btn.btn--cta-white {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100px;
  box-shadow: none;
  align-items: center;
  color: #FFFFFF;
  display: inline-flex;
  padding: 0.3125rem 1.25rem;
  border: 1px solid #FFFFFF;
}
.btn.btn--cta-white.bg-white {
  background-color: #FFFFFF;
}
.btn.btn--cta-white:hover {
  background-color: #FFFFFF;
  color: #000000;
  opacity: 1;
}
.btn.btn--cta-white:hover svg {
  fill: none;
  stroke: #FFFFFF;
  transition: all 250ms ease-in-out;
}
.btn.btn--cta-white:hover svg circle,
.btn.btn--cta-white:hover svg path,
.btn.btn--cta-white:hover svg polygon,
.btn.btn--cta-white:hover svg rect {
  fill: none;
  stroke: #FFFFFF;
}

.h-hide {
  display: none !important;
  visibility: hidden;
}

.h-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.h-noscroll {
  overflow: hidden;
}

.h-opacity-zero {
  opacity: 0;
}

.icon {
  display: block;
  flex-shrink: 0;
  pointer-events: none;
}
.icon.icon--rotate-45 {
  transform: rotate(45deg);
}
.icon.icon--rotate-90 {
  transform: rotate(90deg);
}
.icon.icon--rotate-180 {
  transform: rotate(180deg);
}
.icon.icon--rotate-270 {
  transform: rotate(270deg);
}
.icon.icon--yellow-fill {
  fill: #FFFF85;
}
.icon.icon--yellow-fill circle,
.icon.icon--yellow-fill path,
.icon.icon--yellow-fill polygon,
.icon.icon--yellow-fill rect {
  fill: #FFFF85;
}
.icon.icon--yellow-stroke {
  fill: none;
  stroke: #FFFF85;
}
.icon.icon--yellow-stroke circle,
.icon.icon--yellow-stroke path,
.icon.icon--yellow-stroke polygon,
.icon.icon--yellow-stroke rect {
  fill: none;
  stroke: #FFFF85;
}
.icon.icon--white-fill {
  fill: #FFFFFF;
}
.icon.icon--white-fill circle,
.icon.icon--white-fill path,
.icon.icon--white-fill polygon,
.icon.icon--white-fill rect {
  fill: #FFFFFF;
}
.icon.icon--white-stroke {
  fill: none;
  stroke: #FFFFFF;
}
.icon.icon--white-stroke circle,
.icon.icon--white-stroke path,
.icon.icon--white-stroke polygon,
.icon.icon--white-stroke rect {
  fill: none;
  stroke: #FFFFFF;
}
.icon.icon--gray-light-fill {
  fill: #F9F9F9;
}
.icon.icon--gray-light-fill circle,
.icon.icon--gray-light-fill path,
.icon.icon--gray-light-fill polygon,
.icon.icon--gray-light-fill rect {
  fill: #F9F9F9;
}
.icon.icon--gray-light-stroke {
  fill: none;
  stroke: #F9F9F9;
}
.icon.icon--gray-light-stroke circle,
.icon.icon--gray-light-stroke path,
.icon.icon--gray-light-stroke polygon,
.icon.icon--gray-light-stroke rect {
  fill: none;
  stroke: #F9F9F9;
}
.icon.icon--gray-fill {
  fill: #5C5C5C;
}
.icon.icon--gray-fill circle,
.icon.icon--gray-fill path,
.icon.icon--gray-fill polygon,
.icon.icon--gray-fill rect {
  fill: #5C5C5C;
}
.icon.icon--gray-stroke {
  fill: none;
  stroke: #5C5C5C;
}
.icon.icon--gray-stroke circle,
.icon.icon--gray-stroke path,
.icon.icon--gray-stroke polygon,
.icon.icon--gray-stroke rect {
  fill: none;
  stroke: #5C5C5C;
}
.icon.icon--gray-dark-fill {
  fill: #333333;
}
.icon.icon--gray-dark-fill circle,
.icon.icon--gray-dark-fill path,
.icon.icon--gray-dark-fill polygon,
.icon.icon--gray-dark-fill rect {
  fill: #333333;
}
.icon.icon--gray-dark-stroke {
  fill: none;
  stroke: #333333;
}
.icon.icon--gray-dark-stroke circle,
.icon.icon--gray-dark-stroke path,
.icon.icon--gray-dark-stroke polygon,
.icon.icon--gray-dark-stroke rect {
  fill: none;
  stroke: #333333;
}
.icon.icon--black-fill {
  fill: #000000;
}
.icon.icon--black-fill circle,
.icon.icon--black-fill path,
.icon.icon--black-fill polygon,
.icon.icon--black-fill rect {
  fill: #000000;
}
.icon.icon--black-stroke {
  fill: none;
  stroke: #000000;
}
.icon.icon--black-stroke circle,
.icon.icon--black-stroke path,
.icon.icon--black-stroke polygon,
.icon.icon--black-stroke rect {
  fill: none;
  stroke: #000000;
}
.icon.icon--blue-fill {
  fill: #195AC2;
}
.icon.icon--blue-fill circle,
.icon.icon--blue-fill path,
.icon.icon--blue-fill polygon,
.icon.icon--blue-fill rect {
  fill: #195AC2;
}
.icon.icon--blue-stroke {
  fill: none;
  stroke: #195AC2;
}
.icon.icon--blue-stroke circle,
.icon.icon--blue-stroke path,
.icon.icon--blue-stroke polygon,
.icon.icon--blue-stroke rect {
  fill: none;
  stroke: #195AC2;
}
.icon.icon--name {
  height: 2.75rem;
  width: 7.5rem;
}
@media (min-width: 48em) {
  .icon.icon--name {
    height: 3.75rem;
    width: 10rem;
  }
}
.icon.icon-logo {
  height: 3.875rem;
  width: 13.0625rem;
}
.icon.icon-arrow {
  height: 1.25rem;
  width: 1.25rem;
}
@media (min-width: 48em) {
  .icon.icon-arrow {
    height: 1.875rem;
    width: 1.875rem;
  }
}
.icon.footer-logo {
  height: 3.3125rem;
  width: 11.25rem;
}
.icon.social-icons {
  height: 1.5625rem;
  width: 1.5625rem;
}

.marquee {
  --gap: 0rem;
  bottom: 1.25rem;
  display: flex;
  gap: var(--gap);
  position: absolute;
  overflow: hidden;
  user-select: none;
  width: 100%;
  z-index: 3;
  /* Pause animation when reduced-motion is set */
  /* Enable animation when elements are duplicated */
  /* Pause animation when mouse is hover */
}
.marquee .marquee-inner {
  display: flex;
  flex-shrink: 0;
  gap: var(--gap);
  justify-content: space-around;
  min-width: 100%;
  will-change: transform;
}
.marquee .marquee-inner .marquee-item {
  margin-left: 1.25rem;
  position: relative;
}
.marquee .marquee-inner .marquee-item .label {
  color: #000000;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 400;
  display: flex;
}
@media (min-width: 48em) {
  .marquee .marquee-inner .marquee-item .label {
    font-size: 2.5rem;
  }
}
.marquee .marquee-inner .marquee-item .label a {
  color: #000000;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
.marquee .marquee-inner .marquee-item .label a:hover {
  color: #000000;
  text-decoration: none;
}
.marquee .marquee-inner .marquee-item .label .icon-arrow {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.625rem;
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
@media (prefers-reduced-motion: reduce) {
  .marquee .marquee-inner {
    animation-play-state: paused !important;
  }
}
.marquee.marquee--enable-animation .marquee-inner {
  animation: scroll 45s linear infinite;
}
.marquee.marquee--pause-hover:hover .marquee-inner {
  animation-play-state: paused;
}

/************************/
/* Swiper general rules */
/************************/
.swiper {
  z-index: 0;
}
.swiper .swiper-button {
  display: inline-block;
  height: auto;
  margin: 0 5px 5px;
  opacity: 1;
  position: static;
  transition: all 250ms ease-in-out;
  width: auto;
}
.swiper .swiper-button.swiper-button-disabled {
  opacity: 0.25;
}
.swiper .swiper-button:hover {
  opacity: 0.75;
}
@media (min-width: 48em) {
  .swiper .swiper-button {
    display: none;
  }
}
.swiper .swiper-wrapper .swiper-slide {
  height: 100%;
  width: auto;
}

.swiper-button {
  display: inline-block;
  height: auto;
  margin: 0 5px 5px;
  opacity: 1;
  position: static;
  transition: all 250ms ease-in-out;
  width: auto;
}
.swiper-button.swiper-button-disabled {
  opacity: 0.25;
}
.swiper-button:hover {
  opacity: 0.75;
}
@media (min-width: 48em) {
  .swiper-button {
    display: none;
  }
}

/*******************/
/* Custom swipers */
/*******************/
.slider-container {
  display: flex;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-align: center;
}
.slider-container .swiper--vertical {
  height: 52vw;
}
.slider-container .swiper--vertical .swiper-wrapper {
  height: 100%;
  transition-timing-function: linear;
}
.slider-container .swiper--vertical .swiper-wrapper .swiper-slide {
  height: 26vw;
  opacity: 0;
  transition: all 1s ease-in;
}
.slider-container .swiper--vertical .swiper-wrapper .swiper-slide.swiper-slide-prev {
  opacity: 0;
  transform: rotateX(90deg);
}
.slider-container .swiper--vertical .swiper-wrapper .swiper-slide.swiper-slide-active {
  transform: rotateX(0);
  opacity: 1;
}
.slider-container .swiper--vertical .swiper-wrapper .swiper-slide.swiper-slide-next {
  transform: rotateX(-90deg);
  opacity: 0;
}
@media (min-width: 48em) {
  .slider-container {
    margin-top: auto;
    margin-left: 1.5625rem;
    text-align: left;
  }
  .slider-container .swiper--vertical {
    height: 42vw;
  }
  .slider-container .swiper--vertical .swiper-slide {
    height: 14vw;
  }
}

.swiper--case-study {
  overflow: hidden;
  height: 100%;
}
.swiper--case-study .swiper-wrapper {
  display: inline-flex;
  height: 100%;
  transition-timing-function: linear;
}
.swiper--case-study .swiper-wrapper .swiper-slide {
  display: flex;
  height: 100%;
}
.swiper--case-study .swiper-navigation {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  position: absolute;
  bottom: 1.25rem;
  right: calc(50% - 3.4375rem);
  width: 6.875rem;
}
@media (min-width: 48em) {
  .swiper--case-study .swiper-navigation {
    position: absolute;
    top: unset;
    bottom: 1.25rem;
    right: 3.125rem;
  }
}
.swiper--case-study .swiper-navigation .swiper-button-next,
.swiper--case-study .swiper-navigation .swiper-button-prev {
  background-color: black;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  color: unset;
  cursor: pointer;
  display: flex;
  height: unset;
  margin-top: 0;
  max-height: 50px;
  aspect-ratio: 1/1;
  position: relative;
  top: unset;
  width: unset;
  z-index: 2;
}
.swiper--case-study .swiper-navigation .swiper-button-next svg,
.swiper--case-study .swiper-navigation .swiper-button-prev svg {
  fill: #FFFFFF;
  max-height: 1.5625rem;
  margin: auto;
  stroke: #FFFFFF;
}
.swiper--case-study .swiper-navigation .swiper-button-next.swiper-button-disabled,
.swiper--case-study .swiper-navigation .swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.swiper--case-study .swiper-navigation .swiper-button-next:after,
.swiper--case-study .swiper-navigation .swiper-button-prev:after {
  display: none;
}
.swiper--case-study .swiper-navigation .icon-arrow {
  margin-left: 0;
}

.desktop-footer {
  color: #FFFFFF;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 400;
  display: none;
  background-color: #000000;
  height: 7.125rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
@media (min-width: 48em) {
  .desktop-footer {
    font-size: 1rem;
  }
}
.desktop-footer .footer-content {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-left: 4.1666666667vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.desktop-footer .footer-content .footer-logo svg {
  fill: #FFFFFF;
  stroke-width: 0 !important;
}
.desktop-footer .footer-content .footer-logo svg circle,
.desktop-footer .footer-content .footer-logo svg path,
.desktop-footer .footer-content .footer-logo svg polygon,
.desktop-footer .footer-content .footer-logo svg rect {
  fill: #FFFFFF;
}
.desktop-footer .footer-content .footer-socials {
  display: flex;
  margin-bottom: 0.25rem;
}
.desktop-footer .footer-content .footer-socials a {
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  width: max-content;
}
.desktop-footer .footer-content .footer-socials a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.desktop-footer .footer-content .footer-socials a:not(:last-of-type) {
  margin-right: 1.25rem;
}
.desktop-footer .footer-content .footer-socials a svg {
  fill: #FFFFFF;
}
.desktop-footer .footer-content .footer-socials a svg circle,
.desktop-footer .footer-content .footer-socials a svg path,
.desktop-footer .footer-content .footer-socials a svg polygon,
.desktop-footer .footer-content .footer-socials a svg rect {
  fill: #FFFFFF;
}
.desktop-footer .footer-content .footer-links {
  display: inline-block;
}
.desktop-footer .footer-content .footer-links .footer-link {
  margin-right: 1.875rem;
}
.desktop-footer .footer-content .footer-links .footer-link a {
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
.desktop-footer .footer-content .footer-links .footer-link a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.desktop-footer .footer-content .footer-links .footer-link a.underline {
  text-decoration: underline;
}
.desktop-footer .footer-content .footer-links .footer-link:last-child {
  margin-right: 0;
}
@media (min-width: 48em) {
  .desktop-footer {
    display: block;
  }
}

.section.section--mobile-footer {
  color: #FFFFFF;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 400;
  background-color: #000000;
  display: block;
  height: auto;
  padding-top: 1.875rem;
  padding-bottom: 0.3125rem;
}
@media (min-width: 48em) {
  .section.section--mobile-footer {
    font-size: 1rem;
  }
}
.section.section--mobile-footer .footer-content {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-left: 4.1666666667vw;
}
.section.section--mobile-footer .footer-content .footer-logo svg {
  fill: #FFFFFF;
  stroke-width: 0 !important;
}
.section.section--mobile-footer .footer-content .footer-logo svg circle,
.section.section--mobile-footer .footer-content .footer-logo svg path,
.section.section--mobile-footer .footer-content .footer-logo svg polygon,
.section.section--mobile-footer .footer-content .footer-logo svg rect {
  fill: #FFFFFF;
}
.section.section--mobile-footer .footer-content .footer-socials {
  display: flex;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
@media (min-width: 48em) {
  .section.section--mobile-footer .footer-content .footer-socials {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.section.section--mobile-footer .footer-content .footer-socials a {
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  width: max-content;
}
.section.section--mobile-footer .footer-content .footer-socials a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.section.section--mobile-footer .footer-content .footer-socials a:not(:last-of-type) {
  margin-right: 1.25rem;
}
.section.section--mobile-footer .footer-content .footer-socials a svg {
  fill: #FFFFFF;
}
.section.section--mobile-footer .footer-content .footer-socials a svg circle,
.section.section--mobile-footer .footer-content .footer-socials a svg path,
.section.section--mobile-footer .footer-content .footer-socials a svg polygon,
.section.section--mobile-footer .footer-content .footer-socials a svg rect {
  fill: #FFFFFF;
}
.section.section--mobile-footer .footer-content .footer-link {
  margin-bottom: 0.25rem;
}
.section.section--mobile-footer .footer-content .footer-link a {
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
.section.section--mobile-footer .footer-content .footer-link a:hover {
  color: #FFFFFF;
  text-decoration: none;
}
.section.section--mobile-footer .footer-content .footer-link a.underline {
  text-decoration: underline;
}
.section.section--mobile-footer .footer-content .footer-link:last-child {
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .section.section--mobile-footer {
    display: none;
  }
}

#fp-nav {
  display: none;
}

.header {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #FFFFFF;
  transition: all 250ms ease-in-out;
}
.header .header-wrapper {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-left: 4.1666666667vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: relative;
}
.header .header-wrapper .logo {
  align-items: center;
  display: flex;
}
.header .header-wrapper .logo a svg {
  fill: #000000;
}
.header .header-wrapper .logo a svg circle,
.header .header-wrapper .logo a svg path,
.header .header-wrapper .logo a svg polygon,
.header .header-wrapper .logo a svg rect {
  fill: #000000;
}
.header .header-wrapper .logo a:hover {
  opacity: 0.8;
}
@media (min-width: 48em) {
  .header .header-wrapper .logo {
    margin: 0;
  }
}
.header .header-wrapper .header-menu {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.0625rem;
  display: flex;
}
@media (min-width: 48em) {
  .header .header-wrapper .header-menu {
    font-size: 1.125rem;
  }
}
.header .header-wrapper .header-menu .header-menu-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
@media (min-width: 48em) {
  .header .header-wrapper .header-menu .header-menu-wrapper {
    justify-content: flex-end;
  }
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary {
  padding-inline-start: 0;
  display: none;
}
@media (min-width: 48em) {
  .header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary {
    display: flex;
    align-items: center;
  }
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li {
  list-style: none;
  min-width: 5rem;
  text-align: center;
}
@media (min-width: 48em) {
  .header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li:first-child {
    margin-left: 0;
  }
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a {
  color: #000000;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  padding: 0;
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a:hover {
  color: #000000;
  text-decoration: none;
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a.active {
  pointer-events: none;
  font-weight: 700;
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a:hover {
  opacity: 1;
  font-weight: bold;
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li .btn--link {
  background-color: #000000;
  border-radius: 100px;
  box-shadow: none;
  align-items: center;
  color: #FFFFFF;
  display: inline-flex;
  padding: 0.3125rem 1.25rem;
  border: 1px solid #000000;
}
.header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li .btn--link:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  opacity: 1;
  font-weight: normal;
}
@media (min-width: 48em) {
  .header .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li {
    display: inline;
    margin-left: 1.875rem;
  }
}
.header.transparent {
  background-color: transparent;
}
.header.transparent .header-wrapper .logo a {
  pointer-events: none;
}
.header.transparent .header-wrapper .logo a svg {
  fill: #000000;
}
.header.transparent .header-wrapper .logo a svg circle,
.header.transparent .header-wrapper .logo a svg path,
.header.transparent .header-wrapper .logo a svg polygon,
.header.transparent .header-wrapper .logo a svg rect {
  fill: #000000;
}
.header.transparent .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a {
  color: #000000;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}
.header.transparent .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a:hover {
  color: #000000;
  text-decoration: none;
}
.header.transparent .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li a:hover {
  opacity: 0.8;
}
.header.transparent .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li .btn--link {
  color: #FFFFFF;
}
.header.transparent .header-wrapper .header-menu .header-menu-wrapper .primary-menu .menu--primary li .btn--link:hover {
  color: #FFFFFF;
  opacity: 1;
}
@media (min-width: 48em) {
  .header {
    display: block;
  }
}

.section {
  background-color: bisque;
  position: relative;
  height: 100vh;
  height: calc(100vh - 6.25rem);
  height: 100dvh;
}
.section .background {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
}
.section .background img {
  height: 100%;
  object-fit: cover;
  margin: auto;
}
.section .section-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-left: 4.1666666667vw;
  display: flex;
  height: 100dvh;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.section .section-container .section-label {
  background-color: #000000;
  border-radius: 1.875rem;
  color: #FFFFFF;
  font-size: 0.875rem;
  margin-bottom: 1.875rem;
  padding: 0.3125rem 0.625rem;
  width: max-content;
}
@media (min-width: 48em) {
  .section .section-container .section-label {
    font-size: 1rem;
    padding: 0.625rem 1.25rem;
  }
}
.section .section-container .title-xxxl {
  color: #000000;
  margin: 0;
  font-family: "MangoGrotesque";
  font-size: 26vw;
  line-height: 1;
  font-weight: normal;
  font-size: 32vw;
  line-height: 0.8;
}
@media (min-width: 48em) {
  .section .section-container .title-xxxl {
    font-size: 14vw;
  }
}
@media (min-width: 48em) {
  .section .section-container .title-xxxl {
    font-size: 18vw;
  }
}
.section .section-container .title-xxl {
  color: #000000;
  margin: 0;
  font-family: "MangoGrotesque";
  font-size: 26vw;
  line-height: 1;
  font-weight: normal;
  margin-top: auto;
  text-align: center;
}
@media (min-width: 48em) {
  .section .section-container .title-xxl {
    font-size: 14vw;
  }
}
@media (min-width: 48em) {
  .section .section-container .title-xxl {
    margin-bottom: auto;
    text-align: left;
  }
}
.section .section-container .title-xl {
  color: #000000;
  margin: 0;
  font-family: "MangoGrotesque";
  font-size: 18vw;
  line-height: 0.85;
  font-weight: normal;
}
@media (min-width: 48em) {
  .section .section-container .title-xl {
    font-size: 10vw;
    line-height: 0.8;
  }
}
@media (min-width: 48em) {
  .section .section-container {
    height: calc(100vh - 6.375rem);
  }
}

.section--approccio {
  background-color: #EFE9D9;
}
.section--approccio .background img {
  margin-left: 0;
}
.section--approccio .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--approccio .section-container .right-container {
  margin-bottom: auto;
}
.section--approccio .section-container .right-container .right-content {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
.section--approccio .section-container .right-container .right-content .text-content {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
@media (min-width: 48em) {
  .section--approccio .section-container .right-container .right-content .text-content {
    font-size: 1.125rem;
  }
}
@media (min-width: 48em) {
  .section--approccio .section-container .right-container .right-content .text-content {
    margin-top: 2.1875rem;
  }
}
@media (min-width: 48em) {
  .section--approccio .section-container .right-container .right-content {
    flex: 0 0 29.1666666667vw;
    max-width: 29.1666666667vw;
    margin-left: 50vw;
  }
}
@media (min-width: 48em) {
  .section--approccio .section-container .right-container {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--approccio {
    padding-top: 6.375rem;
  }
}

.section--call-for-artists {
  background-color: #000000;
}
.section--call-for-artists .background {
  display: flex;
  height: 100%;
}
.section--call-for-artists .background picture {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: auto;
}
.section--call-for-artists .background picture img {
  width: 100%;
  max-height: 10dvh;
}
@media (min-width: 48em) {
  .section--call-for-artists .background picture {
    margin-top: 0;
  }
  .section--call-for-artists .background picture img {
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    max-height: unset;
    width: unset;
  }
}
.section--call-for-artists .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--call-for-artists .section-container .section-label {
  background-color: #FFFFFF;
  color: #000000;
}
.section--call-for-artists .section-container .title-xl {
  color: #FFFFFF;
  max-width: unset;
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .title-xl {
    max-width: 55vw;
  }
}
.section--call-for-artists .section-container .bottom-text {
  color: #FFFFFF;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--call-for-artists .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-bottom: 1.875rem;
}
.section--call-for-artists .section-container .bottom-text .text-container .description-desktop {
  display: none;
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .bottom-text .text-container .description-desktop {
    display: block;
  }
}
.section--call-for-artists .section-container .bottom-text .text-container .description-mobile {
  display: block;
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .bottom-text .text-container .description-mobile {
    display: none;
  }
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .bottom-text .text-container {
    flex: 0 0 45.8333333333vw;
    max-width: 45.8333333333vw;
  }
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .bottom-text {
    margin-top: auto;
  }
}
.section--call-for-artists .section-container .buttons {
  margin-top: 0;
}
.section--call-for-artists .section-container .buttons .btn--cta-white:not(:first-of-type) {
  margin-top: 0.625rem;
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .buttons .btn--cta-white:not(:first-of-type) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.section--call-for-artists .section-container .buttons .btn--cta-white.bg-white {
  color: #000000;
}
@media (min-width: 48em) {
  .section--call-for-artists .section-container .buttons {
    margin-top: 10vh;
  }
}
@media (min-width: 48em) {
  .section--call-for-artists {
    padding-top: 6.375rem;
  }
}

.section--case-study {
  background-color: #EFE9D9;
}
.section--case-study .background img {
  margin-left: auto;
  margin-right: 0;
}
.section--case-study .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  width: 100%;
}
.section--case-study .section-container .section-label {
  color: #EFE9D9;
}
.section--case-study .section-container .title-xl {
  max-width: unset;
}
@media (min-width: 48em) {
  .section--case-study .section-container .title-xl {
    max-width: 55vw;
  }
}
.section--case-study .section-container .bottom-text {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--case-study .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--case-study .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
.section--case-study .section-container .bottom-text .text-container small {
  line-height: 1;
}
@media (min-width: 48em) {
  .section--case-study .section-container .bottom-text .text-container {
    flex: 0 0 25vw;
    max-width: 25vw;
  }
}
.section--case-study .section-container .bottom-text .text-container .btn {
  margin-top: 0.625rem;
}
@media (min-width: 48em) {
  .section--case-study .section-container .bottom-text {
    margin-top: auto;
  }
}
.section--case-study .image {
  display: flex;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.section--case-study .image img {
  height: 100%;
  object-fit: cover;
  margin: auto;
  min-width: 100dvw;
  width: 100%;
}
.section--case-study.alternative-layout {
  display: none;
}
.section--case-study.alternative-layout .section-container {
  position: absolute;
}
.section--case-study.alternative-layout .section-container .section-label {
  background-color: #FFFFFF;
  color: #000000;
  mix-blend-mode: screen; /* This makes the cutout text possible */
}
.section--case-study.alternative-layout .section-container .title-xl {
  color: #FFFFFF;
}
.section--case-study.alternative-layout .section-container .bottom-text {
  color: #FFFFFF;
}
.section--case-study.alternative-layout .image::after {
  content: "";
  opacity: 10%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
}
@media (min-width: 48em) {
  .section--case-study.alternative-layout {
    display: block;
  }
}
@media (min-width: 48em) {
  .section--case-study {
    display: none;
    padding-top: 6.375rem;
  }
}

.section--chi-siamo {
  background-color: #EFE9D9;
}
.section--chi-siamo .background img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media (min-width: 48em) {
  .section--chi-siamo .background img {
    margin-left: 0;
    margin-right: auto;
    width: auto;
  }
}
.section--chi-siamo .section-container {
  padding-top: 1.875rem;
  padding-bottom: 9rem;
  flex-direction: column;
}
.section--chi-siamo .section-container .left-container .section-label {
  color: #EFE9D9;
}
@media (min-width: 48em) {
  .section--chi-siamo .section-container .left-container {
    flex: 0 0 50vw;
    max-width: 50vw;
  }
}
.section--chi-siamo .section-container .right-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  height: auto;
}
.section--chi-siamo .section-container .right-container .right-column {
  height: 100%;
}
.section--chi-siamo .section-container .right-container .right-column .right-content {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (min-width: 48em) {
  .section--chi-siamo .section-container .right-container .right-column .right-content {
    font-size: 1.125rem;
  }
}
.section--chi-siamo .section-container .right-container .right-column .right-content .bottom-text {
  color: #000000;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 400;
  margin-top: auto;
}
@media (min-width: 48em) {
  .section--chi-siamo .section-container .right-container .right-column .right-content .bottom-text {
    font-size: 1rem;
  }
}
@media (min-width: 48em) {
  .section--chi-siamo .section-container .right-container .right-column .right-content {
    flex: 0 0 91.6666666667vw;
    max-width: 91.6666666667vw;
    margin-left: 4.1666666667vw;
  }
  .section--chi-siamo .section-container .right-container .right-column .right-content .top-text p,
.section--chi-siamo .section-container .right-container .right-column .right-content .bottom-text p {
    margin: 0;
  }
}
@media (min-width: 48em) {
  .section--chi-siamo .section-container .right-container {
    flex: 0 0 41.6666666667vw;
    max-width: 41.6666666667vw;
    border-left: 1px solid #000000;
    height: 100%;
  }
}
@media (min-width: 48em) {
  .section--chi-siamo .section-container {
    flex-direction: row;
  }
}
@media (min-width: 48em) {
  .section--chi-siamo {
    color: #FFFFFF;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.3;
    font-weight: 400;
    padding-top: 6.375rem;
  }
}
@media (min-width: 48em) and (min-width: 48em) {
  .section--chi-siamo {
    font-size: 1rem;
  }
}

.section--faces-make-places {
  background-color: #195AC2;
}
.section--faces-make-places .background img {
  display: none;
  margin-right: 0;
}
@media (min-width: 48em) {
  .section--faces-make-places .background img {
    display: block;
  }
}
.section--faces-make-places .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--faces-make-places .section-container .section-label {
  background-color: #FFFFFF;
  color: #195AC2;
}
.section--faces-make-places .section-container .title-xl {
  color: #FFFFFF;
  max-width: unset;
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .title-xl {
    max-width: 55vw;
  }
}
.section--faces-make-places .section-container .bottom-text {
  color: #FFFFFF;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--faces-make-places .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-bottom: 1.875rem;
}
.section--faces-make-places .section-container .bottom-text .text-container .description-desktop {
  display: none;
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text .text-container .description-desktop {
    display: block;
  }
}
.section--faces-make-places .section-container .bottom-text .text-container .description-mobile {
  display: block;
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text .text-container .description-mobile {
    display: none;
  }
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text .text-container {
    flex: 0 0 45.8333333333vw;
    max-width: 45.8333333333vw;
  }
}
.section--faces-make-places .section-container .bottom-text .buttons {
  margin-top: 0;
}
.section--faces-make-places .section-container .bottom-text .buttons .btn--cta-white:not(:first-of-type) {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text .buttons .btn--cta-white:not(:first-of-type) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.section--faces-make-places .section-container .bottom-text .buttons .btn--cta-white.bg-white {
  color: #195AC2;
}
.section--faces-make-places .section-container .bottom-text .buttons .btn--cta-white:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  color: #195AC2;
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text .buttons {
    margin-top: 10vh;
  }
}
@media (min-width: 48em) {
  .section--faces-make-places .section-container .bottom-text {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--faces-make-places {
    padding-top: 6.375rem;
  }
}

.section--progetto {
  background-color: #EFE9D9;
}
.section--progetto .background img {
  margin-left: auto;
  margin-right: 0;
}
.section--progetto .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--progetto .section-container .section-label {
  color: #EFE9D9;
}
.section--progetto .section-container .title-xl {
  max-width: unset;
}
@media (min-width: 48em) {
  .section--progetto .section-container .title-xl {
    max-width: 55vw;
  }
}
.section--progetto .section-container .bottom-text {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--progetto .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--progetto .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
@media (min-width: 48em) {
  .section--progetto .section-container .bottom-text .text-container {
    flex: 0 0 25vw;
    max-width: 25vw;
  }
}
@media (min-width: 48em) {
  .section--progetto .section-container .bottom-text {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--progetto {
    padding-top: 6.375rem;
  }
}

.section--hero {
  background-color: #EFE9D9;
}
.section--hero .mobile-logo {
  position: absolute;
  display: block;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 48em) {
  .section--hero .mobile-logo {
    display: none;
  }
}
.section--hero .section-container {
  height: 100vh;
  flex-direction: column;
}
.section--hero .section-container #animated-text {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: auto;
}
@media (min-width: 48em) {
  .section--hero .section-container #animated-text {
    margin-left: 1.5625rem;
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--hero .section-container {
    flex-direction: row;
  }
}

.section--interventi {
  background-color: #EFE9D9;
}
.section--interventi .background img {
  margin-left: 0;
  margin-right: auto;
}
@media (min-width: 48em) {
  .section--interventi .background img {
    margin-left: auto;
    margin-right: 0;
  }
}
.section--interventi .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--interventi .section-container .bottom-text {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--interventi .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--interventi .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
@media (min-width: 48em) {
  .section--interventi .section-container .bottom-text .text-container {
    flex: 0 0 25vw;
    max-width: 25vw;
  }
}
@media (min-width: 48em) {
  .section--interventi .section-container .bottom-text {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--interventi {
    padding-top: 6.375rem;
  }
}

.section--obiettivi {
  background-color: #EFE9D9;
}
.section--obiettivi .background img {
  margin-right: 0;
}
.section--obiettivi .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--obiettivi .section-container .section-label {
  color: #EFE9D9;
}
.section--obiettivi .section-container .title-xl {
  max-width: unset;
}
@media (min-width: 48em) {
  .section--obiettivi .section-container .title-xl {
    max-width: 70vw;
  }
}
.section--obiettivi .section-container .bottom-text {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--obiettivi .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--obiettivi .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
.section--obiettivi .section-container .bottom-text .text-container p:last-child {
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .section--obiettivi .section-container .bottom-text .text-container {
    flex: 0 0 25vw;
    max-width: 25vw;
  }
}
@media (min-width: 48em) {
  .section--obiettivi .section-container .bottom-text {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--obiettivi {
    padding-top: 6.375rem;
  }
}

.section--progetto {
  background-color: #EFE9D9;
}
.section--progetto .background img {
  margin-left: auto;
  margin-right: 0;
}
.section--progetto .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.section--progetto .section-container .section-label {
  color: #EFE9D9;
}
.section--progetto .section-container .title-xl {
  max-width: unset;
}
@media (min-width: 48em) {
  .section--progetto .section-container .title-xl {
    max-width: 55vw;
  }
}
.section--progetto .section-container .bottom-text {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--progetto .section-container .bottom-text {
    font-size: 1.125rem;
  }
}
.section--progetto .section-container .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
@media (min-width: 48em) {
  .section--progetto .section-container .bottom-text .text-container {
    flex: 0 0 25vw;
    max-width: 25vw;
  }
}
@media (min-width: 48em) {
  .section--progetto .section-container .bottom-text {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--progetto {
    padding-top: 6.375rem;
  }
}

.section--projects-carousel {
  background-color: #EFE9D9;
}
.section--projects-carousel .background img {
  margin-left: auto;
  margin-right: 0;
}
.section--projects-carousel .section-container {
  flex-direction: column;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  height: fit-content;
  position: absolute;
  width: auto;
}
.section--projects-carousel .section-container .section-label {
  background-color: #FFFFFF;
  color: #000000;
  mix-blend-mode: screen; /* This makes the cutout text possible */
}
.section--projects-carousel .swiper-slide {
  position: relative;
}
.section--projects-carousel .image {
  display: flex;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.section--projects-carousel .image img {
  height: 100%;
  object-fit: cover;
  margin: auto;
  min-width: 100dvw;
  width: 100%;
}
.section--projects-carousel .image::after {
  content: "";
  opacity: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
}
.section--projects-carousel .content {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
  margin-left: 4.1666666667vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.875rem;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 6.5rem;
  z-index: 2;
}
.section--projects-carousel .content .title-xl {
  color: #FFFFFF;
  margin: 0;
  font-family: "MangoGrotesque";
  font-size: 18vw;
  line-height: 0.85;
  font-weight: normal;
  line-height: 1 !important;
}
@media (min-width: 48em) {
  .section--projects-carousel .content .title-xl {
    font-size: 10vw;
    line-height: 0.8;
  }
}
.section--projects-carousel .content .bottom-text {
  color: #FFFFFF;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}
@media (min-width: 48em) {
  .section--projects-carousel .content .bottom-text {
    font-size: 1.125rem;
  }
}
.section--projects-carousel .content .bottom-text .text-container {
  flex: 0 0 91.6666666667vw;
  max-width: 91.6666666667vw;
}
.section--projects-carousel .content .bottom-text .text-container small {
  line-height: 1;
}
@media (min-width: 48em) {
  .section--projects-carousel .content .bottom-text .text-container {
    flex: 0 0 25vw;
    max-width: 25vw;
  }
}
.section--projects-carousel .content .bottom-text .text-container .btn {
  margin-top: 0.625rem;
}
@media (min-width: 48em) {
  .section--projects-carousel .content .bottom-text {
    margin-top: auto;
  }
}
@media (min-width: 48em) {
  .section--projects-carousel {
    padding-top: 6.375rem;
  }
}