// Typography styles

@mixin basicTypographyStyle($color: $color-black) {
  color: $color;
  margin: 0;
}

@mixin title-1($color: $color-black, $font-weight) {
  @include basicTypographyStyle($color);
  font-family: $font-secondary;
  font-size: 26vw;
  line-height: 1;
  font-weight: $font-weight;

  @include mq(md){
    font-size: $font-xxlarge;
  }
}

@mixin title-2($color: $color-black, $font-weight) {
  @include basicTypographyStyle($color);
  font-family: $font-secondary;
  font-size: 18vw;
  line-height: 0.85;
  font-weight: $font-weight;

  @include mq(md){
    font-size: $font-xlarge;
    line-height: 0.8;
  }
}

@mixin paragraph-lg($color: $color-black, $font-weight) {
  @include basicTypographyStyle($color);
  font-size: $font-discrete;
  line-height: 1.2;
  font-weight: $font-weight;

  @include mq(md){
    font-size: $font-large;
  }
}

@mixin paragraph-md($color: $color-black, $font-weight) {
  @include basicTypographyStyle($color);
  font-size: $font-normal;
  line-height: pxrem(20);
  font-weight: $font-weight;

  @include mq(md){
    font-size: $font-medium;
  }
}

@mixin paragraph($color: $color-black, $font-weight) {
  @include basicTypographyStyle($color);
  font-size: $font-small;
  line-height: 1.3;
  font-weight: $font-weight;

  @include mq(md){
    font-size: $font-normal;
  }
}

@mixin paragraph-small($color: $color-black, $font-weight) {
  @include basicTypographyStyle($color);
  font-size: $font-small;
  line-height: 1;
  font-weight: $font-weight;
}