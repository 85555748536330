.section--hero{
    // background-color: $color-yellow;
    background-color: $color-bg-light;

    .mobile-logo{
        position: absolute;
        display: block;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);

        @include mq(md) {
            display: none;
        }
    }

    .section-container{
        height: 100vh;
        flex-direction: column;

        #animated-text{
            display: flex;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            margin-bottom: auto;

            @include mq(md) {
                margin-left: pxrem(25);
                margin-top: auto;
            }
        }

        @include mq(md) {
            flex-direction: row;
        }
    }
}